import * as Yup from 'yup';
import {
  fieldType,
  dataTypeOptions,
  computeProcessStatuses,
  computeOnDeleteOptions,
} from '@config/constants';
import { computeUrls } from '@config/routes';

const { modelsUrls, enumsUrls, microservicesUrls } = computeUrls;

export const microservicesMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 300,
    type: fieldType.LINK,
    to: '/microservice/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'version',
    headerName: 'Version',
    onForm: true,
    width: 100,
    editable: true,
    value: data ? data.version : null,
    initialValue: '1.0.0',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 300,
    editable: true,
    value: data?.tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const enumsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/enums/',
    width: 300,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const enumValuesMeta = ({ data } = {}) => [
  {
    field: 'value',
    headerName: 'Value',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data ? data.value : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const modelsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    type: fieldType.LINK,
    to: '/models/',
    width: 300,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'label',
    headerName: 'Label',
    onAccordionForm: true,
    editable: true,
    value: data?.label ?? null,
    initialValue: '',
    width: 150,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.tags,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const computeLogsMeta = ({ microserviceId, data } = {}) => [
  {
    field: 'createdAt',
    headerName: 'Date Initiated',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'duration',
    headerName: 'Duration (MS)',
    onAccordionForm: true,
    editable: true,
    value: data ? data.duration : null,
    initialValue: '',
    width: 136,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onAccordionForm: true,
    options: computeProcessStatuses,
    initialValue: computeProcessStatuses[0].value,
    value: data ? data.status : null,
    validate: Yup.string().required(),
  },
  {
    field: 'apiGitRepoUrl',
    headerName: 'API Git Repo URL',
    onAccordionForm: true,
    editable: true,
    value: data ? data.apiGitRepoUrl : null,
    initialValue: '',
    width: 330,
    getHref: (r) => r.apiGitRepoUrl,
    validate: Yup.string(),
    linkInNewTab: true,
  },
  {
    field: 'feGitRepoUrl',
    headerName: 'FE Git Repo URL',
    onAccordionForm: true,
    editable: true,
    value: data ? data.feGitRepoUrl : null,
    initialValue: '',
    width: 330,
    getHref: (r) => r.feGitRepoUrl,
    validate: Yup.string(),
    linkInNewTab: true,
  },
  {
    field: 'devopsGitRepoUrl',
    headerName: 'DevOps Git Repo URL',
    onAccordionForm: true,
    editable: true,
    value: data ? data.devopsGitRepoUrl : null,
    initialValue: '',
    width: 330,
    getHref: (r) => r.devopsGitRepoUrl,
    validate: Yup.string(),
    linkInNewTab: true,
  },
  {
    field: 'failureReason',
    headerName: 'Failure Reason',
    onAccordionForm: true,
    editable: true,
    value: data ? data.failureReason : null,
    initialValue: '',
    width: 690,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
];

export const fieldsMeta = ({ data, microserviceId, microserviceName } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/fields/',
    onAccordionForm: true,
    width: 150,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'label',
    headerName: 'Label',
    onAccordionForm: true,
    editable: true,
    value: data?.label ?? null,
    initialValue: '',
    width: 150,
    validate: Yup.string(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'dataType',
    headerName: 'Data Type',
    type: fieldType.SELECT,
    editable: true,
    width: 100,
    onAccordionForm: true,
    options: dataTypeOptions,
    initialValue: dataTypeOptions[0].value,
    value: data ? data.dataType : null,
    validate: Yup.string().required(),
  },
  {
    field: 'onDelete',
    headerName: 'On Delete',
    type: fieldType.SELECT,
    editable: true,
    width: 100,
    onAccordionForm: true,
    options: computeOnDeleteOptions,
    initialValue: computeOnDeleteOptions[0].value,
    value: data ? data.onDelete : null,
    validate: Yup.string().required(),
  },
  {
    width: 200,
    editable: true,
    field: 'enumDefnId',
    headerName: 'Enum',
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: enumsUrls.list,
    requestKey: `${data?.id}-microservice-enums`,
    validate: Yup.mixed(),
    initialValue: data?.enumDefn
      ? {
          id: data?.enumDefnId,
          label: data?.enumDefn?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&microserviceId=${microserviceId}`,
  },
  {
    field: 'showInTable',
    headerName: 'Show in table?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.showInTable : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isForeignKey',
    headerName: 'Foreign Key?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isForeignKey : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'foreignKeyMicroserviceId',
    headerName: 'Foreign Key Microservice',
    width: 200,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: microservicesUrls.list,
    requestKey: `${data?.id}-microservice-field`,
    validate: Yup.mixed(),
    initialValue:
      data?.foreignKeyMicroserviceId || microserviceId
        ? {
            id: data?.foreignKeyMicroserviceId ?? microserviceId,
            label:
              data?.foreignKeyMicroserviceId?.name ?? microserviceName ?? '',
          }
        : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    onDetailCard: false,
  },
  {
    field: 'foreignKeyModelId',
    headerName: 'Foreign Key Model',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: modelsUrls.list,
    requestKey: `${data?.id}-model-field`,
    validate: Yup.mixed(),
    initialValue: data?.foreignKeyModelId
      ? {
          id: data?.foreignKeyModelId,
          label: data?.foreignKeyModelId?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    urlParams: `&microserviceId=${
      data?.foreignKeyMicroserviceId ?? microserviceId
    }`,
    onDetailCard: false,
  },
  {
    field: 'isOptional',
    headerName: 'Optional?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.isOptional : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isUnique',
    headerName: 'Unique?',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 100,
    editable: true,
    value: data ? data.isUnique : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'isIndex',
    headerName: 'Index?',
    type: fieldType.BOOLEAN,
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.isIndex : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'minLength',
    headerName: 'Min Length',
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.minLength : null,
    initialValue: '',
    validate: Yup.number().positive().nullable(),
  },
  {
    field: 'maxLength',
    headerName: 'Max Length',
    onAccordionForm: true,
    width: 100,
    editable: true,
    value: data ? data.maxLength : null,
    initialValue: '',
    validate: Yup.number().positive().nullable(),
  },
  {
    field: 'defaultValue',
    headerName: 'Default Value',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data?.defaultValue ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const blockGroupsMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number(),
    onDetailCard: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    to: '/block-groups/',
    width: 200,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 300,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const blocksMeta = ({ data, nextOrder } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 65,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder + 1,
    validate: Yup.number(),
  },
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    width: 200,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 100,
    onAccordionForm: true,
    editable: true,
    value: data && data.code,
    initialValue: 1,
    validate: Yup.number(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    width: 300,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 180,
    type: fieldType.TEXT,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
